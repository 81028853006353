import React from 'react'
import { Row, Col } from 'react-bootstrap'
import logo from '../../assets/logo.png'
import { redirectToApp } from '../../utils'

const Header = (props) => {
	return (
		<Row>
			<Col xs={12} className="pt-4">
				<div className="bar-logo-box">
					<img src={logo} className="w-50 logo-img" alt="Geeker" onClick={() => redirectToApp('dashboard', props)} />
				</div>
			</Col>
		</Row>
	)
}

export default Header
