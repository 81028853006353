import apiClient from './index'

export async function getUserByParam(data) {
	return apiClient.post('/users/get_user_by_param', data).then((response) => {
		if (response) {
			return response.data
		}
		return Promise.reject()
	})
}
