import React, { useState, useEffect } from 'react'
import { Row, Col, Alert, Button } from 'react-bootstrap'
import { Modal, Checkbox } from 'antd'
import Loader from './Loader'
import mixpanel from 'mixpanel-browser'
import { JOB_CHARACTERS_ALLOWED } from '../../constants'
import { useNotifications } from '../../context/notificationContext'
import { updateJob } from '../../api/meeting.api'
import * as TechnicianApi from '../../api/technician.api'
import * as SoftwareApi from '../../api/software.api'
import { openNotificationWithIcon } from '../../utils'
import { JobTags } from '../../constants'
import * as JobCycleApi from '../../api/jobCycle.api'
const UpdateJobModel = (props) => {
	const [estTimeFromErrorMsg, setEstTimeFromErrorMsg] = useState('')
	const [estTimeToErrorMsg, setEstTimeToErrorMsg] = useState('')
	const [updateJobErrors, setUpdateJobErrors] = useState({})
	const [jobDetailsErrorMsgShow, setjobDetailsErrorMsgShow] = useState(false)
	const [jobDetailsErrorMsg, setjobDetailsErrorMsg] = useState('Please select the required fields')
	const [DeclinedReason, setDeclinedReasons] = useState('')
	const [subSoftwareList, setSubSoftwareList] = useState([])
	const [selectedSubSoftware, setSelectedSubSoftware] = useState('')
	const [jobUpdatedIssueDescription, setJobUpdatedIssueDescription] = useState('')
	const [estTimeFrom, setEstTimeFrom] = useState(1)
	const [estTimeTo, setEstTimeTo] = useState(1)
	const [hireExpert, setHireExpert] = useState(false)
	const { createNotification, fetchNotifications } = useNotifications()
	const [softwareList, setSoftwareList] = useState([])
	const [areaList, setAreaList] = useState([])
	const [jobUpdatedData, setJobUpdatedData] = useState({
		software: props.job.software.id,
		subSoftware: props.job.subSoftware ? props.job.subSoftware.id : '',
		subOption: props.job.subOption ? props.job.subOption : '',
		updatedIssueDescription: [],
		estimatedTime: '',
	})
	const [count, setCount] = useState(500)
	const [characterCount, setCharacterCount] = useState(jobUpdatedIssueDescription.length)
	/**
	 * first time set variable
	 * @response : set variable of setsoftwarelist, fetchSubSoftwareList and setAreaList
	 * @author : Ridhima
	 */
	useEffect(() => {
		async function fetchSoftwares() {
			const res = await SoftwareApi.getSoftwareList()
			if (res && res.data) {
				setSoftwareList(res.data)
				fetchSubSoftwareList(res.data, props.job.software.id)
				const optionsList = props.job.subSoftware ? props.job.subSoftware.sub_option : props.job.software.sub_option
				setAreaList(optionsList)

				props.setDataLoaded(true)
				props.setShowTransfterCallLoader(false)
			}
		}
		fetchSoftwares()
	}, [])

	/**
	 * When job data updated
	 * @response : set variables setEstTimeFrom, setEstTimeTo, setSelectedSubSoftware, setJobUpdatedIssueDescription and setAreaList
	 * @author : Ridhima
	 */
	useEffect(() => {
		setEstTimeFrom(props.job.time.from)
		setEstTimeTo(props.job.time.to)
		setSelectedSubSoftware(props.job.subSoftware ? props.job.subSoftware.id : '')
		if (props.job.updatedIssueDescription && props.job.updatedIssueDescription.length > 0) {
			setJobUpdatedIssueDescription(props.job.updatedIssueDescription[props.job.updatedIssueDescription.length - 1].issueDescription)
		} else {
			setJobUpdatedIssueDescription(props.job.issueDescription)
		}
		const optionsList = props.job.subSoftware ? props.job.subSoftware.sub_option : props.job.software.sub_option
		setAreaList(optionsList)
	}, [props.job])

	/**
	 * call when user click on hireExpert
	 * @response : set variable setHireExpert
	 * @author : Ridhima
	 */
	function hireExpertChanged(e) {
		setHireExpert(e.target.checked)
	}

	/**
	 * when software change
	 * @response : fetch all sub softwares of selected software
	 * @author : Ridhima
	 */
	const onSoftwareChange = async (e) => {
		const sid = e.target.value
		fetchSubSoftwareList(softwareList, sid)
		updateOptionsList(sid, 'software')
	}

	/**
	 * ??
	 * @response :
	 * @author : Ridhima
	 */
	const updateOptionsList = (software_id, col) => {
		setjobDetailsErrorMsgShow(false)
		const obj = softwareList.find((s) => s.id === software_id)
		const jobDataToUpdate = { ...jobUpdatedData }
		if (obj) {
			setAreaList(obj.sub_option)
			jobDataToUpdate['subOption'] = ''
			jobDataToUpdate[col] = software_id
			setJobUpdatedData(jobDataToUpdate)
			obj.sub_option.map(function (o) {
				if (o === props.job.subOption) {
					jobDataToUpdate['subOption'] = o
					setJobUpdatedData(jobDataToUpdate)
				}
				return true
			})
		} else {
			jobDataToUpdate[col] = software_id
			setJobUpdatedData(jobDataToUpdate)
		}
	}

	useEffect(() => {
		setCharacterCount(jobUpdatedIssueDescription.length)
	}, [jobUpdatedIssueDescription])

	/**
	 * when description changed
	 * @response : limit 200 characters in description
	 * @author : Ridhima
	 */
	const onIssueDescriptionChange = (e) => {
		const data = e.target.value.trim()
		if (data === '') {
			setCharacterCount(0)
		}
		//   else if (data.length > 501) {
		// 	setjobDetailsErrorMsgShow(true)
		// 	setCharacterCount(e.target.value.length);
		// 	setjobDetailsErrorMsg('maximum 500 characters are allowed')
		//   }
		else {
			setCharacterCount(e.target.value.length)
		}

		// if (e.target.value.length > 200) {
		// 	setjobDetailsErrorMsgShow(true)
		// 	setCharacterCount(e.target.value.length);
		// 	setjobDetailsErrorMsg('maximum 200 characters are allowed')
		// }
		setJobUpdatedIssueDescription(e.target.value)
	}

	/**
	 * when estTime updated
	 * @response : set variable setEstTimeFrom or setEstTimeTo
	 * @author : Ridhima
	 */
	const setEstTime = (e, type) => {
		const val = e.target.value
		const intVal = parseInt(val)
		if (type === 'from') {
			setEstTimeFrom(intVal)
		}
		if (type === 'to') {
			setEstTimeTo(intVal)
		}
	}

	/**
	 * on change of sub-software
	 * @response : set value of setSelectedSubSoftware and update list of area options
	 * @author : Ridhima
	 */
	const subSoftwareChange = (e) => {
		setSelectedSubSoftware(e.target.value)
		updateOptionsList(e.target.value, 'subSoftware')
	}

	/**
	 * on change of software area
	 * @response : set error false and set the area option
	 * @author : Ridhima
	 */
	const onAreaChange = (e) => {
		setjobDetailsErrorMsgShow(false)
		const jobDataToUpdate = { ...jobUpdatedData }
		jobDataToUpdate['subOption'] = e.target.value
		setJobUpdatedData(jobDataToUpdate)
	}

	/**
	 * get list of sub softwares
	 * @response : set sub softwares list by selected software
	 * @author : Ridhima
	 */
	const fetchSubSoftwareList = (softwares, software_id) => {
		const subSoftList = []
		softwares.map((s, i) => {
			// if parent value not equal to zero and parent id equal to software_id
			if ((s.parent !== 0 || s.parent !== '0') && s.parent === software_id) {
				subSoftList.push(s)
			}
			if (i + 1 === softwares.length) {
				setSubSoftwareList(subSoftList)
				if (subSoftList.length === 0) {
					setSelectedSubSoftware('')
				}
			}
			return true
		})
	}

	/**
	 * After submit form
	 * @response : updated job data
	 * @author : Ridhima
	 */
	const HandleSubmitedJob = async (e) => {
		e.currentTarget.disabled = true
		setEstTimeFromErrorMsg('')
		setEstTimeToErrorMsg('')
		localStorage.removeItem('customerResponceBackForTransfer')

		let data = { ...jobUpdatedData }
		data['subSoftware'] = selectedSubSoftware
		data['estimatedTime'] = estTimeFrom + '-' + estTimeTo
		const validationRespose = validateJobDetail(data)

		// set validation error
		setUpdateJobErrors(validationRespose['transferCallErrorsTemp'])
		// set updatedIssueDescription array for job
		data['updatedIssueDescription'] = updateIssueDescription(data)

		//If valid is true then update job data
		if (validationRespose['isValidJobDetail']) {
			try {
				data['is_transferred_hire_expert'] = hireExpert
				if (props.isTransferCall) {
					data['is_transferred'] = true
					data = await transferCallData(data)
					await updateJob(props.job.id, {
						dataToUpdate: data,
						cuId: props?.user?.id,
					})
					// mixpanel code//
					mixpanel.identify(props.user.email)
					mixpanel.track(
						`${hireExpert}` == true
							? 'Technician- Updated job details (Transfer call) with hire expert'
							: 'Technician- Updated job details (Transfer call)',
						{ JobId: props.job.id },
					)
					await JobCycleApi.create(JobTags.TECHNICIAN_TRANSFER, props.job.id)
					// mixpanel code//
					transferCallNotification()
				} else {
					// mixpanel code//
					mixpanel.identify(props.user.email)
					mixpanel.track(`Technician - Updated job details`, { JobId: props.job.id })
					// mixpanel code//
					await updateJob(props.job.id, {
						dataToUpdate: data,
						cuId: props?.user?.id,
					})
					openNotificationWithIcon('success', 'Success', 'Job Details has been updated.')
					data['jobId'] = props.job.id
					props.socket.emit('Update:job-details', data)
				}
				props.setShowUpdateJobModal(!props.showUpdateJobModal)
				e.currentTarget.disabled = false
			} catch (error) {
				console.log('Error in fetch meeting :::: ', error)
				e.currentTarget.disabled = false
			}
		} else {
			e.currentTarget.disabled = false
		}
	}

	/**
	 * change job data if is transfer call
	 * @params : form data
	 * @response : updated job data
	 * @author : Ridhima
	 */
	const transferCallData = async (data) => {
		const techDeclined = [...props.job.tech_declined_ids]
		const reasonsTech = [...props.job.reasons]
		techDeclined.push(props.job.technician.id)
		reasonsTech.push(DeclinedReason)
		TechnicianApi.updateTechnician(props.job.technician.id, { status: 'Available', cuId: props?.user?.id })
		const updatedNotifiedTechs = await declineJobForNotifiedTech()
		data['tech_declined_ids'] = techDeclined
		data['notifiedTechs'] = updatedNotifiedTechs
		data['reasons'] = reasonsTech
		data['status'] = 'Pending'
		data['transfer_on_dashboard'] = true
		// Below  code is commented because for transfer job we want deduction of money from customer
		// data['total_time'] = 0
		// data['meeting_start_time'] = ''
		// data['technician'] = ''
		// data['confirmedNotes'] = ''
		// data['customerConfirmedNotes'] = false
		// data['customerDeclinedNotes'] = false
		// data['technicianSubmitNotes'] = false
		// data['meeting_pause'] = false
		// data['meetingStarted'] = false
		// data['technician_paused_timer'] = false
		// data['customer_paused_timer'] = false
		// data['total_pause_seconds'] = 0
		// data['total_seconds'] = 0
		return data
	}

	/**
	 * change job data if is transfer call
	 * @params : form data
	 * @response : updated job data
	 * @author : Ridhima
	 */
	const transferCallNotification = () => {
		openNotificationWithIcon('success', 'Success', 'Transfer successfully initiated.')
		setTimeout(function () {
			const notificationData = {
				user: props?.job?.customer?.user?.id,
				job: props.job.id,
				actionable: false,
				customer: props.job.customer.id,
				read: false,
				title: 'Technician has quit your call.Please wait for another technician to join',
				type: 'job_declined_by_technician',
			}
			createNotification(notificationData)
			fetchNotifications({ user: props?.job?.customer?.user?.id })
		}, 1000)
		props.socket.emit('transfered:call', {
			customerUserId: props?.job?.customer?.user?.id,
			jobId: props.job.id,
			userType: props.job.customer.user.userType,
			customerName: props.job.customer.user.firstName,
			technicianNAme: props.job.technician.user.firstName,
			customerNumber: props.job.customer.phoneNumber,
			hireExpert: hireExpert,
		})
	}
	/**
	 * add / update updatedIssueDescription array
	 * @params : form data
	 * @response : updatedIssueDescription updated array
	 * @author : Ridhima
	 */
	const updateIssueDescription = (data) => {
		const tempDescription = {
			technician: props.user && props.user.technician ? props.job.technician.id : props?.user?.id,
			technicianName: props.user ? props.user.firstName + ' ' + props.user.lastName : '',
			issueDescription: jobUpdatedIssueDescription,
			updatedAt: new Date(),
		}
		const updatedIssueDescriptionStats = [...data['updatedIssueDescription']]
		if (updatedIssueDescriptionStats.length === 0) {
			if ((data['issueDescription'] && data['issueDescription'].toLowerCase().trim()) !== jobUpdatedIssueDescription.toLowerCase().trim()) {
				updatedIssueDescriptionStats.push(tempDescription)
				return updatedIssueDescriptionStats
			}
		} else {
			const descriptionFound = updatedIssueDescriptionStats.find((o) => o.issueDescription === jobUpdatedIssueDescription)
			if (!descriptionFound) {
				updatedIssueDescriptionStats.push(tempDescription)
				return updatedIssueDescriptionStats
			}
		}
	}

	/**
	 * Validate update job and transfer call data
	 * @params : form data
	 * @response : error message and isValidJobDetail(true/false)
	 * @author : Ridhima
	 */
	const validateJobDetail = (data) => {
		const transferCallErrorsTemp = {}
		let isValidJobDetail = true

		if (props.isTransferCall && (DeclinedReason === '' || DeclinedReason.trim() === '')) {
			transferCallErrorsTemp['emptyReason'] = true
			isValidJobDetail = false
		}

		if (data['software'] === '') {
			isValidJobDetail = false
			transferCallErrorsTemp['emptySoftware'] = true
		}
		if (selectedSubSoftware === '' && subSoftwareList.length > 0) {
			isValidJobDetail = false
			transferCallErrorsTemp['emptySubSoftware'] = true
		}
		if (data['subOption'] === '') {
			isValidJobDetail = false
			transferCallErrorsTemp['emptyArea'] = true
		}
		if (jobUpdatedIssueDescription.trim() === '') {
			isValidJobDetail = false
			transferCallErrorsTemp['emptyIssueDescription'] = true
		}
		// if (jobUpdatedIssueDescription.length > 500) {
		// 	isValidJobDetail = false
		// 	transferCallErrorsTemp['emptyIssueDescription'] = true
		// }
		if (estTimeFrom < 1) {
			isValidJobDetail = false
			transferCallErrorsTemp['emptyEstTimeFrom'] = true
			setEstTimeFromErrorMsg('Please enter `Est. time from` value in minutes.')
		}

		if (estTimeTo > 120) {
			isValidJobDetail = false
			transferCallErrorsTemp['emptyEstTimeTo'] = true
			setEstTimeToErrorMsg('`Est. time to` value cannot be greater than 2 hours.')
		}
		if (estTimeTo < 1) {
			isValidJobDetail = false
			transferCallErrorsTemp['emptyEstTimeTo'] = true
			setEstTimeToErrorMsg('Please enter `Est. time to` value in minutes.')
		}
		if (estTimeFrom > estTimeTo) {
			isValidJobDetail = false
			transferCallErrorsTemp['emptyEstTimeFrom'] = true
			setEstTimeFromErrorMsg('`Est. time from` value should be less as compare to `Est. time to`.')
		}
		return { transferCallErrorsTemp: transferCallErrorsTemp, isValidJobDetail: isValidJobDetail }
	}

	/**
	 * Update status of technician jobstatus in notified array in case of transfer call
	 * @response : change notified technician jobstatus
	 * @author : Ridhima
	 */
	const declineJobForNotifiedTech = async () => {
		const updatedNotifiedTechs = []
		for (const k in props.job.notifiedTechs) {
			let jobStatus = props.job.notifiedTechs[k]['jobStatus']
			let notifyEndAt = props.job.notifiedTechs[k]['notifyEndAt'] ? props.job.notifiedTechs[k]['notifyEndAt'] : new Date()
			if (props.job.notifiedTechs[k]['techId'] === props.job.technician.id) {
				jobStatus = 'tech-decline'
				notifyEndAt = new Date()
			}
			updatedNotifiedTechs[k] = {
				techId: props.job.notifiedTechs[k]['techId'],
				techStatus: props.job.notifiedTechs[k]['techStatus'],
				notifyAt: props.job.notifiedTechs[k]['notifyAt'],
				jobStatus: jobStatus,
				notifyEndAt: notifyEndAt,
			}
		}
		return updatedNotifiedTechs
	}

	return (
		<Modal
			title={props.isTransferCall ? 'Transfer Call:' : 'Update Job Details:'}
			// onCancel={() => {
			// 	props.setShowUpdateJobModal(false)
			// }}
			visible={props.showUpdateJobModal}
			className="rejectJobModal"
			footer={[
				<Button
					className="btn app-btn app-btn-light-blue app-btn-small declne-job-btn"
					onClick={() => {
						props.setShowUpdateJobModal(false)
					}}
					disabled={props.disableTransferCancelBtn}
				>
					<span></span>Cancel
				</Button>,
				<Button className="btn app-btn app-btn-small declne-job-btn" onClick={HandleSubmitedJob}>
					<span></span>Submit
				</Button>,
			]}
		>
			<Row className="transfer-call-outer">
				<Loader height="100%" className={props.showTransfterCallLoader ? 'loader-outer' : 'd-none'} />
				{props.isTransferCall && (
					<Col xs={12} className="">
						<div className="text-muted small-text input-notes-text input-notes-modal">
							Please correct the job details as per client requirement before decline.
						</div>
					</Col>
				)}
				{jobDetailsErrorMsgShow && (
					<Alert variant="danger" className="w-100">
						{jobDetailsErrorMsg}
					</Alert>
				)}
				<Col xs={12} className="mt-2">
					<label className="m-0 font-weight-bold">
						Select Software <span className="red-text pl-1">*</span>
					</label>
					<select className="form-control" onChange={onSoftwareChange} defaultValue={props.job.software.id}>
						<option key="" value="">
							--Select--
						</option>
						{softwareList.map((item) => {
							return (
								<option key={item.id} value={item.id}>
									{item.name}
								</option>
							)
							return null
						})}
					</select>
					<div className={(updateJobErrors.emptySoftware ? '' : 'd-none') + ' red-text'}>Please select the software.</div>
				</Col>
				{subSoftwareList.length > 0 && (
					<Col xs={12} className="mt-2">
						<label className="m-0 font-weight-bold">
							Select Sub Software <span className="red-text pl-1">*</span>
						</label>
						<select
							className="form-control"
							onChange={subSoftwareChange}
							defaultValue={props.job.subSoftware ? props.job.subSoftware.id : props.job.software.id}
						>
							<option key="" value="">
								--Select--
							</option>
							{subSoftwareList.map((item) => {
								if (item.parent !== '0' || item.parent !== 0) {
									return (
										<option key={item.id} value={item.id}>
											{item.name}
										</option>
									)
								}
								return null
							})}
						</select>
						<div className={(updateJobErrors.emptySubSoftware ? '' : 'd-none') + ' red-text'}>Please select the subsoftware.</div>
					</Col>
				)}
				<Col xs={12} className="mt-2">
					<label className="m-0 font-weight-bold">
						Select Area <span className="red-text pl-1">*</span>
					</label>
					<select className="form-control" onChange={onAreaChange} defaultValue={props.job.subOption}>
						<option key="select" value="">
							--Select--
						</option>
						{areaList.map((item) => {
							return (
								<option key={item.name} value={item.name}>
									{item.name}
								</option>
							)
						})}
					</select>
					<div className={(updateJobErrors.emptyArea ? '' : 'd-none') + ' red-text'}>Please select the area.</div>
				</Col>
				{props.showHireExpertCheckbox && (
					<Col xs={12} className="mt-2">
						<Checkbox disabled onChange={hireExpertChanged}>
							Transfer to tier 2?
						</Checkbox>
					</Col>
				)}
				<Col xs={12} className="mt-2">
					<label className="m-0 font-weight-bold">
						Issue Description <span className="red-text pl-1">*</span>
					</label>
					<textarea
						className="form-control"
						value={jobUpdatedIssueDescription}
						onChange={onIssueDescriptionChange}
						spellCheck="true"
						// maxLength={JOB_CHARACTERS_ALLOWED}
					></textarea>
					<div className={(updateJobErrors.emptyIssueDescription ? '' : 'd-none') + ' red-text'}>Please enter issue description.</div>
					<div style={{ width: '100%', maxWidth: '600px', textAlign: 'end' }}>
						{/* <span style={(`${count - characterCount}` == 0)  ? {color:"red"} : {color:""}}> ({`${count - characterCount} character left`}) </span> */}
					</div>
				</Col>
				<Col xs={6} className="mt-2">
					<label className="m-0 font-weight-bold">
						Est. Time From <span className="red-text pl-1">*</span>
					</label>
					<input
						type="number"
						className="form-control"
						value={estTimeFrom}
						onChange={(e) => setEstTime(e, 'from')}
						placeholder="From"
						min="1"
					/>
					<div className={(updateJobErrors.emptyEstTimeFrom ? '' : 'd-none') + ' red-text'}>{estTimeFromErrorMsg}</div>
				</Col>
				<Col xs={6} className="mt-2">
					<label className="m-0 font-weight-bold">
						Est. Time To <span className="red-text pl-1">*</span>
					</label>
					<input type="number" className="form-control" value={estTimeTo} onChange={(e) => setEstTime(e, 'to')} placeholder="To" min="1" />
					<div className={(updateJobErrors.emptyEstTimeTo ? '' : 'd-none') + ' red-text'}>{estTimeToErrorMsg}</div>
				</Col>
				{props.isTransferCall && (
					<>
						<Col xs={12} className="mt-2">
							<label className="m-0 font-weight-bold">
								Reason for transfer (please add notes for next technician). <span className="red-text pl-1">*</span>
							</label>
							<textarea
								onChange={(e) => {
									setDeclinedReasons(e.target.value)
								}}
								type="text"
								id="ReasonToDecline"
								className="form-control rejectJobClass"
								spellCheck="true"
							/>
							<div className={(updateJobErrors.emptyReason ? '' : 'd-none') + ' red-text'}>Please enter the reason to decline job.</div>
						</Col>
					</>
				)}
			</Row>
		</Modal>
	)
}
export default UpdateJobModel
