import React, { useEffect, useState } from 'react'
import { Modal } from 'antd'
import Button from 'react-bootstrap/Button'
import { getParticipantDetails, openNotificationWithIcon } from '../../../utils'
export const AutomaticCallConnectModal = ({ openAutomaticCallModal, setAutomaticCallModal, disconnectedUserType, jitsiApi, job, userType }) => {
	const [timer, setTimer] = useState(() => {
		if (!job?.meeting_start_time) {
			return 15
		}
		return 10
	})
	const [popUpAlreadyShown, setPopupAlreadyShown] = useState(false)
	const [userName, setUserName] = useState('')

	useEffect(() => {
		if (job?.meeting_start_time && jitsiApi) {
			participantLength()
		}
	}, [job, jitsiApi])

	const participantLength = async () => {
		const partisipantsArrInitial = await getParticipantDetails(jitsiApi, userType)
		if (partisipantsArrInitial && partisipantsArrInitial.length > 1) {
			setAutomaticCallModal(false)
		}
	}

	useEffect(() => {
		if (job && disconnectedUserType) {
			let fullName = ''
			if (disconnectedUserType == 'technician') {
				fullName = job?.technician?.user?.firstName + ' ' + job?.technician?.user?.lastName
			}
			if (disconnectedUserType == 'customer') {
				fullName = job?.customer?.user?.firstName + ' ' + job?.customer?.user?.lastName
			}
			console.log('job?.technician?.user?.firstName', disconnectedUserType)
			// console.log("job?.technician?.user?.firstName fullName",fullName)

			if (fullName == '') {
				const userType = disconnectedUserType == 'technician' ? 'Technician' : 'Customer'
				fullName = userType
			}
			setUserName(fullName)
		}
	}, [job, disconnectedUserType])

	useEffect(() => {
		const alreadyPopUpShown = window.sessionStorage.getItem('alreadyInCallPopUpOpen')
		if (alreadyPopUpShown == 'yes') {
			setPopupAlreadyShown(true)
		} else {
			if (timer > 0) {
				let intervalId = setInterval(() => {
					setTimer((prevTimer) => prevTimer - 1)
				}, 1000)

				return () => clearInterval(intervalId)
			}
			if (timer == 0) {
				CallAnotherParticiapant()
			}
		}
	}, [openAutomaticCallModal, timer])

	const CallAnotherParticiapant = () => {
		let finalPhoneNumber = ''
		if (disconnectedUserType == 'technician') {
			finalPhoneNumber = job?.technician?.profile?.confirmId?.phoneNumber
		}
		if (disconnectedUserType == 'customer') {
			finalPhoneNumber = job?.customer?.phoneNumber
		}
		if (finalPhoneNumber && finalPhoneNumber != '') {
			jitsiApi.invite([
				{
					allowed: true,
					number: finalPhoneNumber,
					originalEntry: finalPhoneNumber,
					showCountryCodeReminder: false,
					type: 'phone',
				},
			])
			// If meeting paid timer is not started then we want to show timer next time so in that case we will not  set any sessionStorage
			if (job?.meeting_start_time) {
				window.sessionStorage.setItem(`alreadyInCallPopUpOpen`, 'yes')
			}
			openNotificationWithIcon('success', 'Successfully Invited!!')
		}
		setAutomaticCallModal(false)
	}

	const offAutomaticCallConnectModal = () => {
		setAutomaticCallModal(false)
	}

	return (
		<Modal
			visible={true}
			zIndex={5}
			onCancel={offAutomaticCallConnectModal}
			className="add-card-modal-outer d-flex align-items-center justify-content-sm-center p-5 microphone-modal-style"
			width={800}
			maskClosable={false}
			destroyOnClose={true}
			closable={false}
			bodyStyle={{
				zIndex: 2,
			}}
			footer={
				<>
					{job?.meeting_start_time ? (
						<Button
							variant="outline-info info-button-style"
							onClick={() => {
								offAutomaticCallConnectModal()
							}}
						>
							Cancel
						</Button>
					) : null}
					<Button
						variant="outline-success success-button-style"
						onClick={() => {
							CallAnotherParticiapant()
						}}
					>
						{popUpAlreadyShown ? 'Call' : `Call ( ${timer} ) `}
					</Button>
				</>
			}
		>
			{!job?.meeting_start_time ? (
				<p className="automatic-call-p-style" style={{ textAlign: 'center' }}>{`${
					userName ? userName : 'User'
				} is connecting. If connection fails, the system will automatically call within 15 seconds.`}</p>
			) : (
				<p className="automatic-call-p-style">{`Oops ! It seems like your ${disconnectedUserType} got disconnected.We are doing our best to reconnect ${disconnectedUserType}. In the meantime please call your ${disconnectedUserType} using the call button.`}</p>
			)}
		</Modal>
	)
}
