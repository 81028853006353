import '../../logrocketSetup'
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'
import Header from '../Common/Header'
import { redirectToApp } from '../../utils'
import { JOB_STATUS, MAIN_APP_URL } from '../../constants'
import { getJobInformation } from '../../api/meeting.api'
// import { Provider, ErrorBoundary } from '@rollbar/react'
// import Rollbar from 'rollbar'

const Meeting = () => {
	const { jobId } = useParams()
	const { userType } = useParams()
	const [accessToken, setAccessToken] = useState(null)

	useEffect(() => {
		const accessTokenStatic =
			'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2NDc4NDkzMTgsImlhdCI6MTY0NzgzODUxOCwic3ViIjoidXNyX3Q3N1FSTHd1cXgzYmY5UXltIn0.dHv4Ir3O7sacgoYn4C3j_q0LdpGEk2Tq5oZyswggEKI'
		setAccessToken(accessTokenStatic) //Static
	}, [])

	useEffect(() => {
		;(async () => {
			if (accessToken) {
				/**
				 * Get job information
				 * @params = jobId (Available in url)
				 * @response : Return all the job information
				 * @author : Karan
				 */
				let jobInfo = await getJobInformation(jobId)
				jobInfo = jobInfo && jobInfo.data ? jobInfo.data : {}

				if (jobInfo && jobInfo.id) {
					if (jobInfo.status === JOB_STATUS.IN_PROGRESS) {
						window.location.href = `/meeting/${userType}/${jobInfo.id}`
					} else if (jobInfo.status === JOB_STATUS.COMPLETED) {
						window.location.href = `${MAIN_APP_URL}/meeting-feedback/${jobInfo.id}`
					}
				}
			}
		})()
	}, [accessToken])

	return (
		// <ErrorBoundary>
		<Row>
			<Col lg="12" style={{ height: '100vh' }}>
				<Row>
					<Col md="4" lg="4" className="m-auto text-center pt-5">
						<Header />
					</Col>

					<Col md="12" lg="12" className="text-center mt-5">
						<h2 className="">
							<span>Invalid meeting ID.</span>
							<span className="mt-4 d-block w-100">
								<Link to="/" onClick={() => redirectToApp('dashboard')}>
									Click here{' '}
								</Link>{' '}
								to open home page.
							</span>
						</h2>
					</Col>
				</Row>
			</Col>
		</Row>
		// </ErrorBoundary>
	)
}

export default Meeting
