import React, { useCallback, useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import { MediaPermissionsErrorType, requestMediaPermissions } from 'mic-check'
import { ReactInternetSpeedMeter } from 'react-internet-meter'
import Good from '../../assets/images/Good.png'
import Average from '../../assets/images/Average.png'
import Low from '../../assets/images/Low.png'
import NoSignal from '../../assets/images/noSignal.png'
import InfoIcon from '../../assets/images/information.png'
// import { useJitsiMeet } from '../../context/jitsiMeetContext'
import { useLocation } from 'react-router-dom'
import { getMeetingTimeState } from '../../utils'

/**
 * @author :  Jagroop
 * @description : This component is used to show the Connection status details and is called inside src/component/Meeting
 */

const MeetingStatDetails = ({
	userType,
	setMicroponeStatusCustomer,
	microPhoneStatusCustomer,
	setMicroponeStatusTechnician,
	microPhoneStatusTechnician,
	runningSeconds,
	socket,
	setInternetConnectionStatus,
	phoneStatusAvailability
}) => {
	const [parameterAccess, setParameterAccessedCustomer] = useState({ internetSpeed: 0 })
	const [parameterAccessedTechnician, setParameterAccessedTechnician] = useState({ internetSpeed: 0 })
	const [startDetectInternetIssue, setStartDetectInternetIssue] = useState(false)
	const [startDetectInteretIssueTechnician, setStartDetectInternetIssueTechnician] = useState(false)

	const location = useLocation()
	const intervalTime = getMeetingTimeState(runningSeconds)

	/**
	 * @description : This will listen to the socket event for microphone status and internet speed
	 *                so that we can update the status of the connection detail to both the user.
	 * @author : Jagroop
	 */
	useEffect(() => {
		socket.on('microphone-status:send-back', (data) => {
			if (data?.userType === 'customer') {
				setMicroponeStatusCustomer(data?.microphoneStatus)
			}
			if (data?.userType === 'technician') {
				setMicroponeStatusTechnician(data?.microphoneStatus)
			}
		})

		socket.on('internet-status:send-back', (data) => {
			console.log('internet-status:send-back : internet speed', data)
			if (data?.userType === 'customer') {
				setParameterAccessedCustomer({ internetSpeed: data?.internetSpeed })
				setStartDetectInternetIssue(true)
			}
			if (data?.userType === 'technician') {
				setParameterAccessedTechnician({ internetSpeed: data?.internetSpeed })
				setStartDetectInternetIssueTechnician(true)
			}
			if (data?.filteredResultCustomer > 6) {
				setParameterAccessedCustomer({ internetSpeed: 0 })
			}
			if (data?.filteredResultTechnician > 6) {
				setParameterAccessedTechnician({ internetSpeed: 0 })
			}
		})
	}, [socket])


	// This will check the microphone access of the user for every 2 seconds
	useEffect(() => {
		const intervalId = setInterval(() => {
			checkMicrophoneAcces()
		}, intervalTime)
		return () => clearInterval(intervalId)
	}, [])

	// This will check the microphone access of particular userType
	const checkMicrophoneAcces = useCallback(() => {
		requestMediaPermissions({ audio: true, video: false })
			.then(() => {
				emitSocketForMicrophoneStatus(userType, true)
			})
			.catch((err) => {
				const { type } = err
				emitSocketForMicrophoneStatus(userType, false)
				if (type === MediaPermissionsErrorType.SystemPermissionDenied) {
					console.error('Browser does not have permission to access microphone !!')
				}
				if (type === MediaPermissionsErrorType.UserPermissionDenied) {
					console.error('Please allow access to microphone !!')
				}
			})
	}, [parameterAccess, parameterAccessedTechnician])

	// Emit Socket event for microphone status
	const emitSocketForMicrophoneStatus = (userType, microphoneStatus) => {
		const JobId = location?.pathname.split('/')
		const data = { userType: userType, microphoneStatus: microphoneStatus, JobId: JobId[JobId.length - 1] }
		socket.emit('microphone-status', data)
	}

	// This will check the internet speed of the user for every 2 seconds and will update the stat image
	const checkNetworkStatus = (speed) => {
		if (Number(speed) < 3) {
			return <img src={Low} className="connection-image-style" alt="connectionImage" />
		}
		if (Number(speed) < 8) {
			return <img src={Average} className="connection-image-style" alt="connectionImage1" />
		} else if (Number(speed) > 8) {
			return <img src={Good} className="connection-image-style" alt="connectionImage2" />
		}
	}

	const checkMicrophoneAccesDetail = (userType) => {
		const condition = userType === 'customer' ? !microPhoneStatusCustomer : !microPhoneStatusTechnician

		return condition ? 'Disconnected' : 'Connected'
	}

	const setInteretSpeed = (data) => {
		const JobId = location?.pathname.split('/')
		const internetSpeedData = {
			internetSpeed: data,
			userType: userType,
			JobId: JobId[JobId.length - 1],
		}
		setInternetConnectionStatus((prev) => ({ ...prev, [userType]: data }))
		socket.emit('internet-status', internetSpeedData)
	}

	return (
		<>
			<ReactInternetSpeedMeter
				pingInterval={intervalTime} // milliseconds
				thresholdUnit="megabyte" // "byte" , "kilobyte", "megabyte"
				threshold={50}
				imageUrl="https://geeker.co/wp-content/uploads/2022/10/ethnic-young-employee-working-laptop-2.png"
				downloadSize="1561257" //bytes
				callbackFunctionOnNetworkDown={(speed) => console.log(``)}
				callbackFunctionOnNetworkTest={(data) => setInteretSpeed(data)}
			/>
			<>
				<p className="heading-style-of-meeting-stat mt-2"> {userType === 'customer' ? 'Customer (You):' : 'Customer:'}</p>
				<Table striped bordered hover className="meeting-sta-detail-style">
					<tbody>
					{userType === 'customer' &&	<tr>
							<td>Phone audio</td>
							<td className={phoneStatusAvailability ? 'is-connected-css' : 'phone-not-connected-css'}>
							   Connected
							</td>
						</tr>}
						<tr>
							<td>Microphone</td>
							<td className={phoneStatusAvailability && userType === 'customer'  ? 'phone-not-connected-css' : checkMicrophoneAccesDetail('customer') === 'Connected' ? 'is-connected-css' : 'is-not-connected-css'}>
								{checkMicrophoneAccesDetail('customer')}
							</td>
						</tr>
						<tr>
							<td>Connection Speed</td>
							<td>{parameterAccess && parameterAccess?.internetSpeed ? checkNetworkStatus(parameterAccess?.internetSpeed) : 'NA'}</td>
						</tr>
					</tbody>
				</Table>
			</>
			<>
				<p className="heading-style-of-meeting-stat mt-2 mb-2">{userType === 'technician' ? 'Technician(You):' : 'Technician:'}</p>
				<Table striped bordered hover className="meeting-sta-detail-style">
					<tbody>
					    {/* <tr>
						   <td>Phone audio</td>
							<td className={phoneStatusAvailability ? 'is-connected-css' : 'phone-not-connected-css'}>
							   Connected
							</td>
						</tr> */}
						<tr>
							<td>Microphone</td>
							<td className={checkMicrophoneAccesDetail('technician') === 'Connected' ? 'is-connected-css' : 'is-not-connected-css'}>
								{checkMicrophoneAccesDetail('technician')}
							</td>
						</tr>
						<tr>
							<td>Connection Speed</td>
							<td>
								{parameterAccessedTechnician && parameterAccessedTechnician?.internetSpeed
									? checkNetworkStatus(parameterAccessedTechnician?.internetSpeed)
									: 'NA'}
							</td>
						</tr>
					</tbody>
				</Table>

				{userType == 'customer' && startDetectInternetIssue && parameterAccess?.internetSpeed < 3 && (
					<div class="alert alert-danger mt-2 d-flex justify-content-center" role="alert">
						Weak internet connection
					</div>
				)}
				{userType == 'technician' && startDetectInteretIssueTechnician && parameterAccessedTechnician?.internetSpeed < 3 && (
					<div class="alert alert-danger mt-2 d-flex justify-content-center" role="alert">
						Weak internet connection
					</div>
				)}
			</>
		</>
	)
}

export default MeetingStatDetails
